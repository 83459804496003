import React from 'react';
import { shape } from 'prop-types';

import { useSearch } from '../../../hooks/context';
import VisFacetedSearchDesktop from './vis-faceted-search.desktop';
import { LAYOUTS } from '../../../constants';
import { HEADER_TYPES } from '../../../components-v2/header/utils/constants';

const LAYOUT_TYPES = [LAYOUTS.TYPE_GRID, LAYOUTS.TYPE_STACK];

const FacetedSearchIntervention = ({ intervention }) => {
  const { layout_options, header } = useSearch();

  const isAllowedLayout = LAYOUT_TYPES.includes(layout_options?.current);
  const hasOfficialStoreHeader = header === HEADER_TYPES.OFFICIAL_STORE;
  const facetedConfig = intervention?.content;
  const showFacetedSearch = isAllowedLayout && !hasOfficialStoreHeader && !!facetedConfig;

  return showFacetedSearch ? <VisFacetedSearchDesktop configuration={facetedConfig} /> : null;
};

export default FacetedSearchIntervention;

FacetedSearchIntervention.propTypes = {
  intervention: shape({}),
};
